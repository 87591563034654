<template>
  <div class="contact-page">
    <div class="main-column">
      <div class="block">
        <div class="column left-column">
          <h3>Pełna wersja naszej strony jest jeszcze w budowie.</h3>
          <p>CHĘTNIE POMOŻEMY W ORGANIZACJI TWOJEGO EVENTU, ZAPOZNAJ SIĘ Z NASZYMI OFERTAMI I NAPISZ DO NAS!</p>
        </div>
        <div class="column right-collumn">
          <h2>MENEDŻEROWIE</h2>
          <div class="manager">
            <h3>MICHAŁ BARAN</h3>
            <p class="contact-info">+48 664 994 155</p>
            <a href="mailto:michal.baran@cocktailservice.pl" class="contact-info email">MICHAL.BARAN@COCKTAILSERVICE.PL</a>
          </div>
          <div class="manager">
            <h3>KRZYSZTOF BRZOSTOWSKI</h3>
            <p class="contact-info">+48 795 776 733</p>
            <a href="mailto:krzysztof.brzostowski@cocktailservice.pl" class="contact-info email" >KRZYSZTOF.BRZOSTOWSKI@COCKTAILSERVICE.PL</a>
          </div>
        </div>
      </div>
      <div class="block">
        <div class="column left-column">
          <p></p>
        </div>
        <div class="column right-collumn">
          <form @submit.prevent="handleSubmit">
            <div class="form-group">
              <select id="destination" placeholder="" v-model="destination" required>
                <option value="" disabled>Wybierz adres docelowy</option>
                <option value="michal.baran@">MICHAL.BARAN@COCKTAILSERVICE.PL</option>
                <option value="email2@example.com">KRZYSZTOF.BRZOSTOWSKI@COCKTAILSERVICE.PL</option>
                <option value="email3@example.com">BIURO@COCKTAILSERVICE.PL</option>
              </select>
            </div>
            <div class="form-group">
              <input type="email" id="email" v-model="email" placeholder="Podaj śwój adres email" required />
            </div>
            <div class="form-group">
              <input type="text" id="name" v-model="subject" placeholder="Podaj temat wiadomości" required />
            </div>
            <div class="form-group">
              <textarea id="message" rows="10" v-model="message" placeholder="Twoja wiadomość" required></textarea>
            </div>
            <button :class="{ 'dark-mode': isDarkMode }" type="submit">Wyślij</button>
          </form>
          <p v-if="successMessage" class="success-message">{{ successMessage }}</p>
        </div>
      </div>
      <div class="block">
        <div class="column left-column">
          <h4>Nasze biuro.</h4>
          <p>
          Cocktail Service sp. z o.o.<br>
          Jagiellońska 82C bud. 109<br>
          03-301, Warszawa</p>
        </div>
        <div class="column right-collumn">
          <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1220.713211224175!2d21.01808438636169!3d52.27195999396307!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x471ecdd476ff71c7%3A0x9085d9431460e4d5!2sCocktail%20Service%20sp.%20z%20o.o.!5e0!3m2!1spl!2spl!4v1717150660342!5m2!1spl!2spl" style="width: 100%; height: 400px;" allowfullscreen="" loading="lazy"></iframe>
        </div>
      </div>
      <div class="footer">
        <a href="mailto:BIURO@COCKTAILSERVICE.PL">BIURO@COCKTAILSERVICE.PL</a>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
export default {
  name: 'ContactPage',
  data() {
    return {
      subject: '',
      email: '',
      message: '',
      successMessage: '',
      destination: '',
    };
  },
  methods: {
    async handleSubmit() {
      const payload = {
        toEmail: this.destination,
        fromEmail: this.email,
        subject: this.subject,
        message: this.message,
      };

      try {
        const response = await axios.post('https://l4887ivvna.execute-api.eu-central-1.amazonaws.com/dev/email', payload);
        console.log('Email sent:', response.data);
        this.successMessage = 'Dziękujemy za wiadomość. Skontaktujemy się z Tobą wkrótce.';
        this.subject = '';
        this.email = '';
        this.message = '';
        this.destination = '';
      } catch (error) {
        console.error('Error sending email:', error);
      }
    }
  }
};
</script>

<style scoped>

/* .block {
  border-bottom: 1px solid;
} */

.contact-page {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  padding-top: 120px;
  box-sizing: border-box;
  background-color: var(--light-color-background);
  width: 80%;
  margin: 0 auto;
  top: 70px;
}
.dark-mode .contact-page {
  background-color: var(--dark-color-background);

}

form {
  text-transform: uppercase;
}

.main-column {
  max-width: 1200px;
  width: 100%;
  padding: 20px;
  box-sizing: border-box;
}

.block {
  display: flex;
  justify-content: space-between;
  padding-bottom: 20px;
  margin-bottom: 20px;
}

.column {
  width: 48%;
  padding-left: 10px;
  padding-right: 10px;
}

.column h2 {
  margin-bottom: 10%;
}
.right-collumn {
  padding-left: 20px;
  padding-right: 10px;
}
.contact-form {
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #fff;
}
.form-group {
  margin-bottom: 15px;
  box-sizing: border-box;
}

.form-group label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
}

.form-group input,
.form-group textarea {
  width: 95%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 3px;
}

.form-group select {
  width: 99%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 3px;
}


.form-group textarea {
  resize: vertical;
}

.left-column {
  border-right: 1px solid; 
}

.left-column h4 {
  margin-top: 40%;
  padding-right: 20px;
}

.left-column h3 {
  margin-top: 26%;
  padding-right: 20px;
}

.left-column p {
  margin-top: 8%;
  padding-right: 20px;
}

 .manager h3 {
 margin: 0;
}

.contact-info {
  font-size: 0.9em; /* Smaller font size for contact information */
}

.footer {
  text-align: center;
  padding-top: 20px;
  border-top: 1px solid ;
}

.email {
  word-wrap: break-word; /* Ensure long words break and wrap */
  overflow-wrap: break-word; /* Modern alternative */
  word-break: break-all; /* Break long words to ensure they fit */
}

.column a {
  display: block;
  color: var(--dark-color-font);
  text-decoration: none;
  margin-bottom: 14px;
}

.footer a {
  display: block;
  color: var(--dark-color-font);
  text-decoration: none;
  margin-bottom: 14px;
}

button {
  
  display: block;
  width: 100%;
  padding: 10px;
  color: var(--light-light-color-font);
  border-radius: 3px;
  cursor: pointer;
  font-size: 16px;
  background-color: var(--light-color-background);
  border: 1px solid var(--);
  margin: 4px 0;
}

.dark-mode button {
  border: 1px solid var(--dark-color-font);
  color: var(--dark-color-font);
  background-color: var(--dark-color-background);
}
button:hover {
  background-color: #b1afaf; 
}

.success-message {
  margin-top: 20px;
  color: green;
  text-align: center;
}


@media (max-width: 768px) {
  .main-column {
    width: 100%; /* Set width to 98% for mobile */
    font-size: 14px;
  }

  .block {
    flex-direction: column;
  }

  .right-collumn {
    padding-left: 10px;
  }
  .column {
    width: 100%;
    margin-bottom: 20px;
  }
  .left-column {
    border-right: none; /* Remove border on smaller screens */
    border-bottom: 1px solid;
  }

  .left-column h4 {
    margin-top: 10PX;
  }

  .left-column h3 {
    margin-top: 10PX;
  }

  .left-column p {
    margin-top: 10px;
  }

  .column:last-child {
    margin-bottom: 0;
  }

  .footer {
    padding-top: 10px;
  }
}
</style>